<template>
  <div class="calc_iva">
    <div class="cab blue-grey lighten-2"></div>

    <v-input_n
      v-bind="$input"
      v-model="T_suplido"
      label="Suplidos"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="T_base"
      label="T. Bases"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="T_iva"
      label="T. Ivas"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-select
      v-bind="$select"
      v-model="tipo_iva"
      label="Iva General"
      :items="get_ivas"
      :disabled="!edicion"
      item-value="id"
      item-text="label"
    >
    </v-select>

    <v-row no-gutters>
      <v-col md="6">
        <v-subheader> % IVA </v-subheader>

        <v-text-field
          v-bind="$input"
          v-model="Aimp['ivapor1']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="Aimp['ivapor2']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="Aimp['ivapor3']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="Aimp['ivapor4']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="Aimp['ivapor5']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-text-field>
      </v-col>

      <v-col md="6">
        <v-subheader> BASE </v-subheader>

        <v-input_n
          v-bind="$input"
          v-model="Aimp['base1']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="Aimp['base2']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="Aimp['base3']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="Aimp['base4']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-input_n>

        <v-input_n
          v-bind="$input"
          v-model="Aimp['base5']"
          label=""
          readonly
          :disabled="!edicion"
        >
        </v-input_n>
      </v-col>
    </v-row>

    <v-input_n
      v-bind="$input"
      v-model="pnfsuma"
      label="Imp. Funeraria"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="sup_pd"
      label="Pago Directo"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="imp_cia"
      label="Imp. CIA"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>
  </div>
</template>

<script>
export default {
  props: {
    schema: { type: Object, default: null },
    edicion: { type: Boolean, default: false }
  },

  data() {
    return {
      Aimp: [],
      tipo_iva: 0,
      T_suplido: 0,
      T_base: 0,
      T_iva: 0,
      pnfsuma: 0,
      sup_pd: 0,
      imp_cia: 0
    };
  },

  created() {
    this.reset();
  },

  computed: {
    get_ivas() {
      return [
        { id: 0, label: "21%" },
        { id: 1, label: "10%" },
        { id: 2, label: "8%" },
        { id: 3, label: "4%" },
        { id: 4, label: "3%" },
        { id: 5, label: "0%" }
      ];
    }
  },

  methods: {
    //
    async ini(records) {
      await this.calculo(records);
    },

    // reseteo array de importes
    reset() {
      this.Aimp = [];

      for (let i = 1; i < 6; i++) {
        this.Aimp["ivapor" + i] = 0;
        this.Aimp["base" + i] = 0;
        this.Aimp["iva" + i] = 0;
      }
    },

    //
    async calculo(records) {
      var base = 0;
      var ivapor = 0;
      var iva = 0;

      // reseteo array
      await this.reset();

      // inicializo variables totales
      this.T_suplido = this.T_base = this.T_iva = 0;
      this.pnfsuma = this.sup_pd = this.imp_cia = 0;

      //
      for (const key in records) {
        const item = records[key];

        // Solo se tratan suplidos en compra
        if (item.sup === "S") {
          this.T_suplido += Number(item.imp);
        }

        if (item.sup == "N") {
          this.T_base += Number(item.imp);
          await this.acumula(item.iva, item.imp);
        }

        //
        this.calculo_detalle(item);
      }

      // obtengo el total de iva
      for (let i = 1; i < 6; i++) {
        base = Number(this.Aimp["base" + i]);
        ivapor = Number(this.Aimp["ivapor" + i]);

        if (base != 0) {
          iva = (Math.abs(base) * ivapor) / 100;

          if (base < 0) iva = iva * -1;

          this.Aimp["iva" + i] = iva;
          this.T_iva += iva;
        }
      }

      this.T_suplido = this.T_suplido.toFixed(2);
      this.T_base = this.T_base.toFixed(2);
      this.T_iva = this.T_iva.toFixed(2);
    },

    //
    acumula(iva, base) {
      if (base === 0) return;

      // acumulo porcentaje de iva
      var tmpbase;
      for (let i = 1; i < 6; i++) {
        if (this.Aimp["ivapor" + i] === iva) {
          tmpbase = Number(this.Aimp["base" + i]);
          tmpbase += Number(base);
          this.Aimp["base" + i] = this.round(tmpbase);
          return;
        }
      }

      // nuevo porcentaje de iva
      for (let i = 1; i < 6; i++) {
        if (this.Aimp["base" + i] === 0) {
          this.Aimp["base" + i] = Number(base);
          this.Aimp["ivapor" + i] = iva;

          // reordeno los ivas poniendo el mayor al principio
          if (i > 1) this.reordena_iva(i);
          return;
        }
      }
    },

    calculo_detalle(item) {
      var iva10 = ["12", "21", "52"];
      var iva_item;
      var imp = Number(item.imp);

      // pnfsuma
      // calculo el importe de la funeraria (todas las lineas sp)
      if (Number(item.sp) === 0)
        this.pnfsuma += imp + this.round((Number(item.iva) * imp) / 100);

      // imp_cia
      // calculo el importe de la compañia
      iva_item = 21; // cojo IVA GLOBAL

      // si son flores al 10
      if (iva10.indexOf(item.prod_id) >= 0 && Number(item.iva) !== 3)
        iva_item = 10;

      // si es de canarias prevalece al 3
      if (item.iva === 3) iva_item = Number(item.iva);

      // si es pago directo y suplido no lleva iva
      if (Number(item.sp) === 1 && item.sup === "S") {
        iva_item = 0;
        this.sup_pd += imp;
      }

      this.imp_cia += imp + this.round((Number(iva_item) * imp) / 100);
      this.imp_cia = this.round(Number(this.imp_cia));
    },

    reordena_iva(_i) {
      var bas = 0;
      var iva = 0;

      for (let i = 1; i <= _i; i++) {
        for (let j = 1; j <= _i; j++) {
          if (i === j) continue;
          if (this.Aimp["ivapor" + i] > this.Aimp["ivapor" + j]) {
            bas = this.Aimp["base" + i];
            iva = this.Aimp["ivapor" + i];

            this.Aimp["base" + i] = this.Aimp["base" + j];
            this.Aimp["ivapor" + i] = this.Aimp["ivapor" + j];
            this.Aimp["base" + j] = bas;
            this.Aimp["ivapor" + j] = iva;
          }
        }
      }
    },

    round(n, d = 100) {
      n = Number((n * d).toFixed(2));
      n = Math.round(n);
      n = n / 100;
      return n;
    },

    round_detalle(n, d = 100) {
      return Math.round(n * d) / d;
    }
  }
};
</script>
